import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { reportsApi } from '../../config/api';

const initial = {
  isProductNamesLoading: false,
  isProductNamesSuccess: false,
  isProductNamesFailed: false,
  productNames: [],
  productNamesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isSubProductNamesLoading: false,
  isSubProductNamesSuccess: false,
  isSubProductNamesFailed: false,
  subProductNames: [],
  subProductNamesErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isReportsPageLisingLoading: false,
  isReportsPageLisingSuccess: false,
  isReportsPageLisingFailed: false,
  reportData: {
    reportsList: [],
    totalCount: 0
  },
  reportsPageListingErrorContainer: {
    error: false,
    message: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getProductNames = createAsyncThunk(
  'report/getProductNames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportsApi.get(`HL/reports`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getSubProductNames = createAsyncThunk(
  'report/getSubProductNames',
  async ({ prodCode, designation }, { rejectWithValue }) => {
    try {
      const response = await reportsApi.get(
        `HL/reportByCode/${prodCode}/${designation}`
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.error);
    }
  }
);

export const getReportsPageList = createAsyncThunk(
  'report/getReportsPageList',
  async ({ payload, prodCode }, { rejectWithValue }) => {
    try {
      let response = await reportsApi.post(`${prodCode}/listing`, payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearReportsData = createAction('CLEAR_REPORTS_DATA');

export const reportSlice = createSlice({
  name: 'report',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(getProductNames.pending, state => {
        state.isProductNamesLoading = true;
        state.isProductNamesSuccess = false;
        state.isProductNamesFailed = false;
        state.productNamesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getProductNames.fulfilled, (state, action) => {
        state.isProductNamesLoading = false;
        state.isProductNamesSuccess = true;
        state.productNames = action.payload;
      })
      .addCase(getProductNames.rejected, (state, action) => {
        state.isProductNamesLoading = false;
        state.isProductNamesFailed = true;
        state.productNamesErrorContainer = {
          ...state.productNamesErrorContainer,
          ...action.payload
        };
      })

      .addCase(getSubProductNames.pending, state => {
        state.isSubProductNamesLoading = true;
        state.isSubProductNamesSuccess = false;
        state.isSubProductNamesFailed = false;
        state.subProductNamesErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getSubProductNames.fulfilled, (state, action) => {
        state.isSubProductNamesLoading = false;
        state.isSubProductNamesSuccess = true;
        state.subProductNames = action.payload;
      })
      .addCase(getSubProductNames.rejected, (state, action) => {
        state.isSubProductNamesLoading = false;
        state.isSubProductNamesFailed = true;
        state.subProductNamesErrorContainer = {
          ...state.subProductNamesErrorContainer,
          ...action.payload
        };
      })
      .addCase(getReportsPageList.pending, state => {
        state.isReportsPageLisingLoading = true;
        state.isReportsPageLisingSuccess = false;
        state.isReportsPageLisingFailed = false;
        state.reportData = initial.reportData;
        state.reportsPageListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getReportsPageList.fulfilled, (state, action) => {
        state.isReportsPageLisingLoading = false;
        state.isReportsPageLisingSuccess = true;
        state.reportData = {
          reportsList: action.payload?.data,
          totalCount: action.payload?.count
        };
      })
      .addCase(getReportsPageList.rejected, (state, action) => {
        state.isReportsPageLisingLoading = false;
        state.isReportsPageLisingFailed = true;
        state.reportData = initial.reportData;
        state.reportsPageListingErrorContainer = {
          ...state.reportsPageListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(clearReportsData, state => {
        state.reportData = initial.reportData;
      });
  }
});
export default reportSlice.reducer;
