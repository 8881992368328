import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../config/api';

const initial = {
  isCompanyListLoading: false,
  isCompanuListSuccess: false,
  isCompanyListFailed: false,
  companyNameListing: null,
  companyNameListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isProductClassificationLoading: false,
  isProductClassificationSuccess: false,
  isProductClassificationFailed: false,
  productClassificationListing: null,
  productClassificationListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isProductTypeLoading: false,
  isProductTypeSuccess: false,
  isProductTypeFailed: false,
  productTypeListing: null,
  productTypeListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddEditGILoading: false,
  isAddEditGISuccess: false,
  isAddEditGIFailed: false,
  addEditResponse: null,
  addEditGIErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isChangeStatusLoading: false,
  isChangeStatusSuccess: false,
  isChangeStatusFailed: false,
  changeStatusResponse: null,
  changeStatusErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFCompanyListLoading: false,
  isMFCompanyListSuccess: false,
  isMFCompanyListFailed: false,
  mfCompanyListing: null,
  mfCompanyListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFFundHouseLoading: false,
  isMFFundHouseSuccess: false,
  isMFFundHouseFailed: false,
  mfFundHouseListing: null,
  mfFundHouseErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isMFSchemeLoading: false,
  isMFSchemeSuccess: false,
  isMFSchemeFailed: false,
  mfSchememListing: null,
  mfSchemeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddEditMFLoading: false,
  isAddEditMFSuccess: false,
  isAddEditMFFailed: false,
  addEditMFRespone: null,
  isAddEditMFErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIdbiProductLoading: false,
  isIdbiProductSuccess: false,
  isIdbiProductFailed: false,
  idbiProductListing: null,
  idbiProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIdbiSubProductLoading: false,
  isIdbiSubProductSuccess: false,
  isIdbiSubProductFailed: false,
  idbiSubProductListing: null,
  idbiSubProductErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIdbiSlabLoading: false,
  isIdbiSlabSuccess: false,
  isIdbiSlabFailed: false,
  idbiSlabListing: null,
  idbiSlabListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIdbiSlabByDataLoading: false,
  isIdbiSlabByDataSuccess: false,
  isIdbiSlabByDataFailed: false,
  idbiSlabByData: null,
  idbiSlabByDataErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isIdbiSchemeLoading: false,
  isIdbiSchemeSuccess: false,
  isIdbiSchemeFailed: false,
  idbiSchememListing: null,
  idbiSchemeErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddEditIdbiLoading: false,
  isAddEditIdbiSuccess: false,
  isAddEditIdbiFailed: false,
  addEditIdbiRespone: null,
  isAddEditIdbiErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPremiumPaymentTypeLoading: false,
  isPremiumPaymentTypeSuccess: false,
  isPremiumPaymentTypeFailed: false,
  premiumPaymentTypeListing: null,
  premiumPaymentTypeListingErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddEditPlansLoading: false,
  isAddEditPlansSuccess: false,
  isAddEditPlansFailed: false,
  addEditPlansResponse: null,
  addEditPlansErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isTermListLoading: false,
  isTermListSuccess: false,
  isTermListFailed: false,
  termList: null,
  termListErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAddEditFdCommissionLoading: false,
  isAddEditFdCommissionSuccess: false,
  isAddEditFdCommissionFailed: false,
  addEditFdCommissionResponse: null,
  addEditFdCommissionErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isLiCommissionLoading: false,
  isLiCommissionSuccess: false,
  isLiCommissionFailed: false,
  liCommissionResponse: null,
  liCommissionErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const getCompanyNameListing = createAsyncThunk(
  'planMaster/getCompanyNameListing',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get('GI/company');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductClassificationListing = createAsyncThunk(
  'planMaster/getProductClassificationListing',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `GI/company/${companyId}/productClassification`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductTypeListing = createAsyncThunk(
  'planMaster/getProductTypeListing',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`GI/company/${companyId}/productType`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEditCompanyProduct = createAsyncThunk(
  'planMaster/addEditCompanyProduct',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.post('GI/addEditCompanyProduct', payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeEnableDisableStatus = createAsyncThunk(
  'planMaster/changeEnableDisableStatus',
  async ({ payload, productId }, { rejectWithValue }) => {
    try {
      let response;
      if (productId === 1) {
        response = await api.put(`HL/purposeScheme/status`, payload);
      } else if (productId === 2) {
        response = await api.put(`LI/enableDisable`, payload);
      } else if (productId === 3) {
        response = await api.put(`GI/enableDisable`, payload);
      } else if (productId === 4) {
        response = await api.put(`FD/enableDisable`, payload);
      } else if (productId === 5) {
        response = await api.put(`MF/enableDisable`, payload);
      } else if (productId === 6) {
        response = await api.put(`IDBI/enableDisable`, payload);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMFCompanyListing = createAsyncThunk(
  'planMaster/getMFCompanyListing',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get('MF/company');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getMFFundHouseListing = createAsyncThunk(
  'planMaster/getMFFundHouseListing',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`MF/company/${companyId}/fundHouses`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getMFSchemeListing = createAsyncThunk(
  'planMaster/getMFSchemeListing',
  async ({ fundHouseId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`MF/fundHouse/${fundHouseId}/schemes`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEditFundHouseScheme = createAsyncThunk(
  'planMaster/addEditFundHouseScheme',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.post('MF/addEditFundHouseScheme', payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIdbiProductsListing = createAsyncThunk(
  'planMaster/getIdbiProductsListing',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get(`IDBI/products`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIdbiSubProductsListing = createAsyncThunk(
  'planMaster/getIdbiSubProductsListing',
  async ({ productId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`IDBI/product/${productId}/subProducts`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIdbiSchemeListing = createAsyncThunk(
  'planMaster/getIdbiSchemeListing',
  async ({ subProductId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`IDBI/subProducts/${subProductId}/schemes`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIdbiSlabListing = createAsyncThunk(
  'planMaster/getIdbiSlabListing',
  async ({ productId, subProductId }, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `IDBI/slab?productId=${productId}&subProductId=${subProductId || ''}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getIdbiSlabByData = createAsyncThunk(
  'planMaster/getIdbiSlabByData',
  async ({ slabId }, { rejectWithValue }) => {
    try {
      let response = await api.get(`IDBI/getAllDataBySlab/${slabId}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEditIdbiScheme = createAsyncThunk(
  'planMaster/addEditIdbiScheme',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.post('IDBI/addEditProductScheme', payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPremiumPaymentTypeList = createAsyncThunk(
  'planMaster/getPremiumPaymentTypeList',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get(`LI/premiumModes`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCommission = createAsyncThunk(
  'planMaster/getCommission',
  async ({ planNo, planId }, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `LI/getCommission?planNo=${planNo}&planId=${planId}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEditPlans = createAsyncThunk(
  'planMaster/addEditPlans',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.post('LI/addEditPlan', payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTermList = createAsyncThunk(
  'planMaster/getTermList',
  async (_, { rejectWithValue }) => {
    try {
      let response = await api.get(`FD/slab`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addEditFdCommission = createAsyncThunk(
  'planMaster/addEditFdCommission',
  async ({ payload }, { rejectWithValue }) => {
    try {
      let response = await api.post('FD/addEditCommission', payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const revertPlanMasterData = createAction('REVERT_PLAN_MASTER_DATA');

export const planMasterSlice = createSlice({
  name: 'planMaster',
  initialState: initial,
  extraReducers: builder => {
    builder

      .addCase(revertPlanMasterData, state => {
        state.isAddEditGILoading = false;
        state.isAddEditGISuccess = false;
        state.isAddEditGIFailed = false;
        state.addEditResponse = null;
        state.addEditGIErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isChangeStatusLoading = false;
        state.isChangeStatusSuccess = false;
        state.isChangeStatusFailed = false;
        state.changeStatusResponse = null;
        state.changeStatusErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isAddEditMFLoading = false;
        state.isAddEditMFSuccess = false;
        state.isAddEditMFFailed = false;
        state.addEditMFRespone = null;
        state.isAddEditMFErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isAddEditIdbiLoading = false;
        state.isAddEditIdbiSuccess = false;
        state.isAddEditIdbiFailed = false;
        state.addEditIdbiRespone = null;
        state.isAddEditIdbiErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isAddEditPlansLoading = false;
        state.isAddEditPlansSuccess = false;
        state.isAddEditPlansFailed = false;
        state.addEditPlansResponse = null;
        state.addEditPlansErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
        state.isAddEditFdCommissionLoading = false;
        state.isAddEditFdCommissionSuccess = false;
        state.isAddEditFdCommissionFailed = false;
        state.addEditFdCommissionResponse = null;
        state.addEditFdCommissionErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isIdbiSlabByDataLoading = false;
        state.isIdbiSlabByDataSuccess = false;
        state.isIdbiSlabByDataFailed = false;
        state.idbiSlabByData = null;
        state.idbiSlabByDataErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };

        state.isIdbiSlabLoading = false;
        state.isIdbiSlabSuccess = false;
        state.isIdbiSlabFailed = false;
        state.idbiSlabListing = null;
        state.idbiSlabListingErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })

      .addCase(getCompanyNameListing.pending, state => {
        state.isCompanyListLoading = true;
        state.isCompanuListSuccess = false;
        state.isCompanyListFailed = false;
        state.companyNameListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCompanyNameListing.fulfilled, (state, action) => {
        state.isCompanyListLoading = false;
        state.isCompanuListSuccess = true;
        state.companyNameListing = action.payload;
      })
      .addCase(getCompanyNameListing.rejected, (state, action) => {
        state.isCompanyListLoading = false;
        state.isCompanyListFailed = true;
        state.companyNameListing = null;
        state.companyNameListingErrorContainer = {
          ...state.companyNameListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(getProductClassificationListing.pending, state => {
        state.isProductClassificationLoading = true;
        state.isProductClassificationSuccess = false;
        state.isProductClassificationFailed = false;
        state.productClassificationListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getProductClassificationListing.fulfilled, (state, action) => {
        state.isProductClassificationLoading = false;
        state.isProductClassificationSuccess = true;
        state.productClassificationListing = action.payload;
      })
      .addCase(getProductClassificationListing.rejected, (state, action) => {
        state.isProductClassificationLoading = false;
        state.isProductClassificationFailed = true;
        state.productClassificationListing = null;
        state.productClassificationListingErrorContainer = {
          ...state.productClassificationListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(getProductTypeListing.pending, state => {
        state.isProductTypeLoading = true;
        state.isProductTypeSuccess = false;
        state.isProductTypeFailed = false;
        state.productTypeListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getProductTypeListing.fulfilled, (state, action) => {
        state.isProductTypeLoading = false;
        state.isProductTypeSuccess = true;
        state.productTypeListing = action.payload;
      })
      .addCase(getProductTypeListing.rejected, (state, action) => {
        state.isProductTypeLoading = false;
        state.isProductTypeFailed = true;
        state.productTypeListing = null;
        state.productTypeListingErrorContainer = {
          ...state.productTypeListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(addEditCompanyProduct.pending, state => {
        state.isAddEditGILoading = true;
        state.isAddEditGISuccess = false;
        state.isAddEditGIFailed = false;
        state.addEditGIErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(addEditCompanyProduct.fulfilled, (state, action) => {
        state.isAddEditGILoading = false;
        state.isAddEditGISuccess = true;
        state.addEditResponse = action.payload;
      })
      .addCase(addEditCompanyProduct.rejected, (state, action) => {
        state.isAddEditGILoading = false;
        state.isAddEditGIFailed = true;
        state.addEditResponse = null;
        state.addEditGIErrorContainer = {
          ...state.addEditGIErrorContainer,
          ...action.payload
        };
      })

      .addCase(changeEnableDisableStatus.pending, state => {
        state.isChangeStatusLoading = true;
        state.isChangeStatusSuccess = false;
        state.isChangeStatusFailed = false;
        state.changeStatusErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(changeEnableDisableStatus.fulfilled, (state, action) => {
        state.isChangeStatusLoading = false;
        state.isChangeStatusSuccess = true;
        state.changeStatusResponse = action.payload;
      })
      .addCase(changeEnableDisableStatus.rejected, (state, action) => {
        state.isChangeStatusLoading = false;
        state.isChangeStatusFailed = true;
        state.changeStatusResponse = null;
        state.changeStatusErrorContainer = {
          ...state.changeStatusErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFCompanyListing.pending, state => {
        state.isMFCompanyListLoading = true;
        state.isMFCompanyListSuccess = false;
        state.isMFCompanyListFailed = false;
        state.mfCompanyListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFCompanyListing.fulfilled, (state, action) => {
        state.isMFCompanyListLoading = false;
        state.isMFCompanyListSuccess = true;
        state.mfCompanyListing = action.payload;
      })
      .addCase(getMFCompanyListing.rejected, (state, action) => {
        state.isMFCompanyListLoading = false;
        state.isMFCompanyListFailed = true;
        state.mfCompanyListing = null;
        state.mfCompanyListErrorContainer = {
          ...state.mfCompanyListErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFFundHouseListing.pending, state => {
        state.isMFFundHouseLoading = true;
        state.isMFFundHouseSuccess = false;
        state.isMFFundHouseFailed = false;
        state.mfFundHouseErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFFundHouseListing.fulfilled, (state, action) => {
        state.isMFFundHouseLoading = false;
        state.isMFFundHouseSuccess = true;
        state.mfFundHouseListing = action.payload;
      })
      .addCase(getMFFundHouseListing.rejected, (state, action) => {
        state.isMFFundHouseLoading = false;
        state.isMFFundHouseFailed = true;
        state.mfFundHouseListing = null;
        state.mfFundHouseErrorContainer = {
          ...state.mfFundHouseErrorContainer,
          ...action.payload
        };
      })

      .addCase(getMFSchemeListing.pending, state => {
        state.isMFSchemeLoading = true;
        state.isMFSchemeSuccess = false;
        state.isMFSchemeFailed = false;
        state.mfSchemeErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getMFSchemeListing.fulfilled, (state, action) => {
        state.isMFSchemeLoading = false;
        state.isMFSchemeSuccess = true;
        state.mfSchememListing = action.payload;
      })
      .addCase(getMFSchemeListing.rejected, (state, action) => {
        state.isMFSchemeLoading = false;
        state.isMFSchemeFailed = true;
        state.mfSchememListing = null;
        state.mfSchemeErrorContainer = {
          ...state.mfSchemeErrorContainer,
          ...action.payload
        };
      })

      .addCase(addEditFundHouseScheme.pending, state => {
        state.isAddEditMFLoading = true;
        state.isAddEditMFSuccess = false;
        state.isAddEditMFFailed = false;
        state.isAddEditMFErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(addEditFundHouseScheme.fulfilled, (state, action) => {
        state.isAddEditMFLoading = false;
        state.isAddEditMFSuccess = true;
        state.addEditMFRespone = action.payload;
      })
      .addCase(addEditFundHouseScheme.rejected, (state, action) => {
        state.isAddEditMFLoading = false;
        state.isAddEditMFFailed = true;
        state.addEditMFRespone = null;
        state.isAddEditMFErrorContainer = {
          ...state.isAddEditMFErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIdbiProductsListing.pending, state => {
        state.isIdbiProductLoading = true;
        state.isIdbiProductSuccess = false;
        state.isIdbiProductFailed = false;
        state.idbiProductErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIdbiProductsListing.fulfilled, (state, action) => {
        state.isIdbiProductLoading = false;
        state.isIdbiProductSuccess = true;
        state.idbiProductListing = action.payload;
      })
      .addCase(getIdbiProductsListing.rejected, (state, action) => {
        state.isIdbiProductLoading = false;
        state.isIdbiProductFailed = true;
        state.idbiProductListing = null;
        state.idbiProductErrorContainer = {
          ...state.idbiProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIdbiSubProductsListing.pending, state => {
        state.isIdbiSubProductLoading = true;
        state.isIdbiSubProductSuccess = false;
        state.isIdbiSubProductFailed = false;
        state.idbiSubProductErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIdbiSubProductsListing.fulfilled, (state, action) => {
        state.isIdbiSubProductLoading = false;
        state.isIdbiSubProductSuccess = true;
        state.idbiSubProductListing = action.payload;
      })
      .addCase(getIdbiSubProductsListing.rejected, (state, action) => {
        state.isIdbiSubProductLoading = false;
        state.isIdbiSubProductFailed = true;
        state.idbiSubProductListing = null;
        state.idbiSubProductErrorContainer = {
          ...state.idbiSubProductErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIdbiSlabListing.pending, state => {
        state.isIdbiSlabLoading = true;
        state.isIdbiSlabSuccess = false;
        state.isIdbiSlabFailed = false;
        state.idbiSlabListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIdbiSlabListing.fulfilled, (state, action) => {
        state.isIdbiSlabLoading = false;
        state.isIdbiSlabSuccess = true;
        state.idbiSlabListing = action.payload;
      })
      .addCase(getIdbiSlabListing.rejected, (state, action) => {
        state.isIdbiSlabLoading = false;
        state.isIdbiSlabFailed = true;
        state.idbiSlabListing = null;
        state.idbiSlabListingErrorContainer = {
          ...state.idbiSlabListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIdbiSchemeListing.pending, state => {
        state.isIdbiSchemeLoading = true;
        state.isIdbiSchemeSuccess = false;
        state.isIdbiSchemeFailed = false;
        state.idbiSchemeErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIdbiSchemeListing.fulfilled, (state, action) => {
        state.isIdbiSchemeLoading = false;
        state.isIdbiSchemeSuccess = true;
        state.idbiSchememListing = action.payload;
      })
      .addCase(getIdbiSchemeListing.rejected, (state, action) => {
        state.isIdbiSchemeLoading = false;
        state.isIdbiSchemeFailed = true;
        state.idbiSchememListing = null;
        state.idbiSchemeErrorContainer = {
          ...state.idbiSchemeErrorContainer,
          ...action.payload
        };
      })

      .addCase(getIdbiSlabByData.pending, state => {
        state.isIdbiSlabByDataLoading = true;
        state.isIdbiSlabByDataSuccess = false;
        state.isIdbiSlabByDataFailed = false;
        state.idbiSlabByDataErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getIdbiSlabByData.fulfilled, (state, action) => {
        state.isIdbiSlabByDataLoading = false;
        state.isIdbiSlabByDataSuccess = true;
        state.idbiSlabByData = action.payload;
      })
      .addCase(getIdbiSlabByData.rejected, (state, action) => {
        state.isIdbiSlabByDataLoading = false;
        state.isIdbiSlabByDataFailed = true;
        state.idbiSlabByData = null;
        state.idbiSlabByDataErrorContainer = {
          ...state.idbiSlabByDataErrorContainer,
          ...action.payload
        };
      })

      .addCase(addEditIdbiScheme.pending, state => {
        state.isAddEditIdbiLoading = true;
        state.isAddEditIdbiSuccess = false;
        state.isAddEditIdbiFailed = false;
        state.isAddEditIdbiErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(addEditIdbiScheme.fulfilled, (state, action) => {
        state.isAddEditIdbiLoading = false;
        state.isAddEditIdbiSuccess = true;
        state.addEditIdbiRespone = action.payload;
      })
      .addCase(addEditIdbiScheme.rejected, (state, action) => {
        state.isAddEditIdbiLoading = false;
        state.isAddEditIdbiFailed = true;
        state.addEditIdbiRespone = null;
        state.isAddEditIdbiErrorContainer = {
          ...state.isAddEditIdbiErrorContainer,
          ...action.payload
        };
      })

      .addCase(getPremiumPaymentTypeList.pending, state => {
        state.isPremiumPaymentTypeLoading = true;
        state.isPremiumPaymentTypeSuccess = false;
        state.isPremiumPaymentTypeFailed = false;
        state.premiumPaymentTypeListingErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getPremiumPaymentTypeList.fulfilled, (state, action) => {
        state.isPremiumPaymentTypeLoading = false;
        state.isPremiumPaymentTypeSuccess = true;
        state.premiumPaymentTypeListing = action.payload;
      })
      .addCase(getPremiumPaymentTypeList.rejected, (state, action) => {
        state.isPremiumPaymentTypeLoading = false;
        state.isPremiumPaymentTypeFailed = true;
        state.premiumPaymentTypeListing = null;
        state.premiumPaymentTypeListingErrorContainer = {
          ...state.premiumPaymentTypeListingErrorContainer,
          ...action.payload
        };
      })

      .addCase(addEditPlans.pending, state => {
        state.isAddEditPlansLoading = true;
        state.isAddEditPlansSuccess = false;
        state.isAddEditPlansFailed = false;
        state.addEditPlansErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(addEditPlans.fulfilled, (state, action) => {
        state.isAddEditPlansLoading = false;
        state.isAddEditPlansSuccess = true;
        state.addEditPlansResponse = action.payload;
      })
      .addCase(addEditPlans.rejected, (state, action) => {
        state.isAddEditPlansLoading = false;
        state.isAddEditPlansFailed = true;
        state.addEditPlansResponse = null;
        state.addEditPlansErrorContainer = {
          ...state.addEditPlansErrorContainer,
          ...action.payload
        };
      })

      .addCase(getTermList.pending, state => {
        state.isTermListLoading = true;
        state.isTermListSuccess = false;
        state.isTermListFailed = false;
        state.termListErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getTermList.fulfilled, (state, action) => {
        state.isTermListLoading = false;
        state.isTermListSuccess = true;
        state.termList = action.payload;
      })
      .addCase(getTermList.rejected, (state, action) => {
        state.isTermListLoading = false;
        state.isTermListFailed = true;
        state.termList = null;
        state.termListErrorContainer = {
          ...state.termListErrorContainer,
          ...action.payload
        };
      })

      .addCase(addEditFdCommission.pending, state => {
        state.isAddEditFdCommissionLoading = true;
        state.isAddEditFdCommissionSuccess = false;
        state.isAddEditFdCommissionFailed = false;
        state.addEditFdCommissionErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(addEditFdCommission.fulfilled, (state, action) => {
        state.isAddEditFdCommissionLoading = false;
        state.isAddEditFdCommissionSuccess = true;
        state.addEditFdCommissionResponse = action.payload;
      })
      .addCase(addEditFdCommission.rejected, (state, action) => {
        state.isAddEditFdCommissionLoading = false;
        state.isAddEditFdCommissionFailed = true;
        state.addEditFdCommissionResponse = null;
        state.addEditFdCommissionErrorContainer = {
          ...state.addEditFdCommissionErrorContainer,
          ...action.payload
        };
      })

      .addCase(getCommission.pending, state => {
        state.isLiCommissionLoading = true;
        state.isLiCommissionSuccess = false;
        state.isLiCommissionFailed = false;
        state.liCommissionErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getCommission.fulfilled, (state, action) => {
        state.isLiCommissionLoading = false;
        state.isLiCommissionSuccess = true;
        state.liCommissionResponse = action.payload;
      })
      .addCase(getCommission.rejected, (state, action) => {
        state.isLiCommissionLoading = false;
        state.isLiCommissionFailed = true;
        state.liCommissionResponse = null;
        state.liCommissionErrorContainer = {
          ...state.liCommissionErrorContainer,
          ...action.payload
        };
      });
  }
});
export default planMasterSlice.reducer;
