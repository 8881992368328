import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, formatAmountInINR, handleError } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import {
  getEmployees,
  getProductNames
} from '../redux/reducers/masterManagementSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomDataTable from '../components/CustomDataTable';
import DownloadButton from '../components/DownloadAsButton';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { AttachmentRounded, HistoryOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  downloadLeadSampleFormat,
  getLeadList,
  revertLeadPageData,
  uploadLeadData
} from '../redux/reducers/leadSlice';
import ModalDialog from '../components/ModalDialog';
import { LoadingButton } from '@mui/lab';
import { useDropzone } from 'react-dropzone';
import { ICON_UPLOAD } from '../utils/imageUrls';
import DeleteIcon from '@mui/icons-material/Delete';
import SnackBarBox from '../components/SnackBarBox';
import { BUSINESS_TYPES } from '../utils/constant';
import { getCwaMenuData } from '../config/cookie';
import Tooltip from '@mui/material/Tooltip';

function CWALead() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authData } = useSelector(state => state.auth);
  const [initialValues, setInitialValues] = useState({
    product: 3,
    page: 0,
    limit: 10,
    searchFilter: '',
    empIds: ['all']
  });
  const { productNames, employeeList } = useSelector(state => state.masters);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuAnchors, setMenuAnchors] = useState({});
  const [uploadLeadDataModalOpen, setUploadLeadDataModalOpen] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([
    { name: 'All', empId: 'all' }
  ]);
  const productId = location?.state?.productId;
  const businessTypeId = location?.state?.businessTypeId;

  const menuList = getCwaMenuData();
  const writeAccess = menuList?.filter(item => item.name === 'Lead');

  const {
    leadData,
    isUploadLeadDataLoading,
    isUploadLeadDataSuccess,
    isUploadLeadDataFailure,
    uploadLeadDataErrorContainer,
    uploadLeadDataStatus
  } = useSelector(state => state.lead);

  const validationSchema = yup.object().shape({
    productName: yup
      .object()
      .shape({
        id: yup.string().required('Product name is required')
      })
      .required('Product name is required'),
    employeeName: yup
      .array()
      .of(yup.string().required('Employee selection is required'))
      .required('Employee selection is required'),
    businessType: yup
      .object()
      .shape({
        id: yup.string().nullable()
      })
      .nullable()
  });

  const formik = useFormik({
    initialValues: {
      productName: productId || productNames[0] || 1,
      employeeName: selectedEmployees,
      businessType: BUSINESS_TYPES[0]
    },
    validationSchema: validationSchema
  });

  useEffect(() => {
    document.title = createTitle('Lead');
    dispatch(setActiveMenu('Lead'));
    dispatch(getProductNames());
    const payload = {
      searchFilter:
        selectedEmployees[0].empId === 'all' ? '' : selectedEmployees
    };
    dispatch(getEmployees({ payload }));
  }, [dispatch]);

  useEffect(() => {
    if (productNames.length > 0) {
      if (productId) {
        const filteredProducts = productNames.filter(
          product => product.id === productId
        );
        formik.setFieldValue('productName', filteredProducts[0]);
      } else {
        formik.setFieldValue('productName', productNames[0]);
      }
    }
  }, [productNames, productId]);

  useEffect(() => {
    if ((productId == 2 || productId == 3) && businessTypeId) {
      if (businessTypeId == 'newBusiness') {
        formik.setFieldValue('businessType', {
          label: 'New Business',
          id: 'newBusiness'
        });
      } else {
        formik.setFieldValue('businessType', {
          label: 'Renewal Business',
          id: 'renewalBusiness'
        });
      }
    }
  }, [businessTypeId]);

  useEffect(() => {
    const employeeIds = selectedEmployees
      .map(employee => employee.empId)
      .join(',');
    dispatch(
      getLeadList({
        payload: {
          product: formik.values.productName?.id || productId,
          page: initialValues.page,
          limit: initialValues.limit,
          searchFilter: initialValues.searchFilter,
          empIds: employeeIds ? employeeIds : initialValues.empIds,
          businessType:
            formik.values.businessType?.id || businessTypeId || 'newBusiness'
        }
      })
    );
    return () => {
      dispatch(revertLeadPageData());
    };
  }, [
    initialValues,
    selectedEmployees,
    formik.values.productName,
    formik.values.businessType
  ]);

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 2 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const debouncedSearch = _.debounce(
    term => setInitialValues({ ...initialValues, searchFilter: term, page: 0 }),
    300
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false, // Allow only a single file
    onDrop: acceptedFiles => {
      // Update the state with the new file
      setUploadedFile(acceptedFiles[0]); // Since only one file is allowed, take the first one
    }
  });

  const handleUpload = event => {
    event.preventDefault();

    if (!uploadedFile) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    formData.append('product', '1');

    dispatch(uploadLeadData({ payload: formData }));
  };

  // Function to remove the file
  const removeFile = () => {
    setUploadedFile(null);
  };

  const handleClick = (event, empId) => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: event.currentTarget
    }));
  };

  const handleClose = empId => {
    setMenuAnchors(prevAnchors => ({
      ...prevAnchors,
      [empId]: null
    }));
  };

  const handleUploadDataClick = () => {
    setUploadLeadDataModalOpen(true);
  };

  const handleDownloadFormatClick = event => {
    event.preventDefault();
    dispatch(downloadLeadSampleFormat());
  };

  const columns = [
    {
      uinqueKey: 'leadId',
      id: 'regionalOffice',
      label: 'Regional Office',
      width: '120px'
    },
    {
      id: 'clusterName',
      label: 'Cluster Name'
    },
    {
      id: 'areaOffice',
      label: 'Area Office'
    },
    {
      id: 'territoryOffice',
      label: 'Territory Office'
    },
    {
      id: 'applicationId',
      label: 'Lead ID'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'customerName',
      label: 'Customer Name'
    },
    {
      id: 'amountRequested',
      label: 'Amount Requested',
      render: row =>
        row.amountRequested ? formatAmountInINR(row.amountRequested) : '-'
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number',
      width: '120px'
    },
    {
      id: 'emailId',
      label: 'Email Id'
    },
    {
      id: 'purpose',
      label: 'Purpose',
      width: '150px'
    },
    {
      id: 'scheme',
      label: 'Scheme',
      width: '150px'
    },

    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress' &&
                      row.status !== 'Login') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`HL/edit`, {
                      state: {
                        leadId: row.leadId,
                        customerId: row.applicantId,
                        productId: formik.values?.productName?.id
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                return navigate(`HL/view-audit-logs`, {
                  state: { leadId: row.leadId, customerId: row.applicantId }
                });
                // return navigate(`GI/view-audit-logs`, {
                //   state: { leadId: row.leadId, customerId: row.applicantId }
                // });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const LIColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'premiumAmount',
      label: 'Amount',
      render: row =>
        row.premiumAmount ? formatAmountInINR(row.premiumAmount) : '-'
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`LI/edit`, {
                      state: {
                        leadId: row.leadId,
                        // leadId: 98,
                        customerId: row.applicantId,
                        productId: 2,
                        businessTypeId:
                          formik.values?.businessType?.id || 'newBusiness'
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>

            <MenuItem
              onClick={() => {
                return navigate(`LI/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.applicantId,
                    businessType: formik.values.businessType
                      ? formik.values.businessType?.id
                      : BUSINESS_TYPES[0]?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const GIColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'premiumAmount',
      label: 'Amount',
      render: row =>
        row.premiumAmount ? formatAmountInINR(row.premiumAmount) : '-'
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`GI/edit`, {
                      state: {
                        leadId: row.leadId,
                        // leadId: 98,
                        customerId: row.applicantId,
                        productId: 3,
                        businessTypeId:
                          formik.values.businessType?.id || 'newBusiness'
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                return navigate(`GI/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.applicantId,
                    businessType: formik.values.businessType
                      ? formik.values.businessType?.id
                      : BUSINESS_TYPES[0]?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const FDColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'amount',
      label: 'Amount',
      render: row => (row.amount ? formatAmountInINR(row.amount) : '-')
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`FD/edit`, {
                      state: {
                        leadId: row.leadId,
                        customerId: row.applicantId,
                        productId: 4
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>

            <MenuItem
              onClick={() => {
                return navigate(`FD/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.applicantId,
                    productId: formik.values.productName?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const NPSColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'amount',
      label: 'Amount',
      render: row => (row.amount ? formatAmountInINR(row.amount) : '-')
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`NPS/edit`, {
                      state: {
                        leadId: row.leadId,
                        customerId: row.customerId,
                        productId: formik.values.productName?.id
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                return navigate(`NPS/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.customerId,
                    productId: formik.values.productName?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const MFColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'amount',
      label: 'Amount',
      render: row => (row.amount ? formatAmountInINR(row.amount) : '-')
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`MF/edit`, {
                      state: {
                        leadId: row.leadId,
                        customerId: row.applicantId,
                        productId: formik.values.productName?.id
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                return navigate(`MF/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.applicantId,
                    productId: formik.values.productName?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const IDBIColumns = [
    {
      uinqueKey: 'leadId',
      id: 'territoryOffice',
      label: 'Territory Office',
      width: '120px'
    },
    {
      id: 'applicationId',
      label: 'Lead Id'
    },
    {
      id: 'dateOfCompletion',
      label: 'Date of Completion',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfCompletion
                  ? moment(row.dateOfCompletion).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'dateOfSale',
      label: 'Date of sale',
      width: '120px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'} color={'inherit'}>
                {row.dateOfSale
                  ? moment(row.dateOfSale).format('DD-MM-YYYY hh:mm A')
                  : '-'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'name',
      label: 'Customer Name',
      width: '150px'
    },
    {
      id: 'amount',
      label: 'Amount',
      render: row => (row.amount ? formatAmountInINR(row.amount) : '-')
    },
    {
      id: 'mobileNumber',
      label: 'Mobile Number'
    },
    {
      id: 'emailId',
      label: 'Email ID',
      width: '150px'
    },
    {
      id: 'status',
      label: 'Status',
      width: '120px'
    },
    {
      id: 'action',
      label: 'Action',
      render: row => (
        <>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${row.leadId}`}
            aria-haspopup="true"
            onClick={event => handleClick(event, row.leadId)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`menu-${row.leadId}`}
            anchorEl={menuAnchors[row.leadId]}
            keepMounted
            open={Boolean(menuAnchors[row.leadId])}
            onClose={() => handleClose(row.leadId)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Tooltip
              title={
                writeAccess[0]?.write === 0 &&
                'You cannot perform this action because you do not have permission to edit. Please contact the admin.'
              }
              arrow
              placement="top"
            >
              <span>
                <MenuItem
                  disabled={
                    (row.status !== 'Submitted' &&
                      row.status !== 'In-progress') ||
                    writeAccess[0]?.write === 0
                  }
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                    '&:hover': { color: '#335DAD' }
                  }}
                  onClick={() => {
                    return navigate(`IDBI/edit`, {
                      state: {
                        leadId: row.leadId,
                        customerId: row.applicantId,
                        productId: formik.values.productName?.id
                      }
                    });
                  }}
                >
                  <EditIcon fontSize="small" sx={{ marginRight: '16px' }} />
                  <Typography variant="inherit">EDIT</Typography>
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem
              onClick={() => {
                return navigate(`IDBI/view-audit-logs`, {
                  state: {
                    leadId: row.leadId,
                    customerId: row.applicantId,
                    productId: formik.values.productName?.id
                  }
                });
              }}
              sx={{
                fontWeight: 'bold',
                fontSize: 'small',
                '&:hover': { color: '#335DAD' }
              }}
            >
              <HistoryOutlined fontSize="small" sx={{ marginRight: '16px' }} />
              <Typography variant="inherit">VIEW AUDIT LOGS</Typography>
            </MenuItem>
          </Menu>
        </>
      )
    }
  ];

  const handleOnChange = newValue => {
    formik.setValues({
      ...formik.values,
      productName: newValue ? newValue : null,
      employeeName: [],
      businessType: BUSINESS_TYPES[0]
    });
    setSelectedEmployees([{ name: 'All', empId: 'all' }]);
  };

  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '12px'
        }}
      >
        <Box>
          <form
            autoComplete="off"
            style={{
              marginLeft: '24px',
              marginRight: '24px',
              marginTop: '8px'
            }}
          >
            <div style={{ display: 'flex' }}>
              <Autocomplete
                options={productNames}
                getOptionLabel={option => option.name || ''}
                value={formik.values.productName}
                size="small"
                disableClearable
                // onChange={(event, newValue) => {
                //   formik.setFieldValue(
                //     'productName',
                //     newValue ? newValue : null
                //   );
                // }}
                onChange={(event, newValue) => handleOnChange(newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Product *"
                    placeholder="Select Product"
                    style={{ width: '300px', marginRight: '24px' }}
                    error={
                      formik.touched.productName &&
                      Boolean(formik.errors.productName)
                    }
                    helperText={
                      formik.touched.productName && formik.errors.productName
                    }
                  />
                )}
              />
              {_.includes([2, 3], formik.values.productName?.id) && (
                <Autocomplete
                  options={BUSINESS_TYPES}
                  getOptionLabel={option => option.label || ''}
                  isOptionEqualToValue={(option, value) => {
                    return option.id === (value ? value.id : null);
                  }}
                  value={formik.values.businessType}
                  size="small"
                  disableClearable
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      'businessType',
                      newValue ? newValue : null
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Business Type *"
                      placeholder="Select Business Type"
                      style={{ width: '300px', marginRight: '24px' }}
                      error={
                        formik.touched.businessType &&
                        Boolean(formik.errors.businessType)
                      }
                      helperText={
                        formik.touched.businessType &&
                        formik.errors.businessType
                      }
                    />
                  )}
                />
              )}
              <Autocomplete
                multiple
                // options={employeeList}
                options={[{ name: 'All', empId: 'all' }, ...employeeList]}
                getOptionLabel={option => option.name || ''}
                value={selectedEmployees}
                onChange={(event, newValue) => {
                  // Check if 'All' is part of the new value array
                  const isAllSelected = newValue.some(
                    option => option.empId === 'all'
                  );
                  if (isAllSelected) {
                    // If 'All' is selected, set the value to only 'All'
                    setSelectedEmployees([{ name: 'All', empId: 'all' }]);
                    formik.setFieldValue('employeeName', [
                      { name: 'All', empId: 'all' }
                    ]);
                  } else {
                    setSelectedEmployees(newValue);
                    formik.setFieldValue('employeeName', newValue);
                  }
                }}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Employee *"
                    placeholder="Select Employee"
                    style={{ width: '300px', marginRight: '24px' }}
                    error={
                      formik.touched.employeeName &&
                      Boolean(formik.errors.employeeName)
                    }
                    helperText={
                      formik.touched.employeeName && formik.errors.employeeName
                    }
                  />
                )}
              />
            </div>
          </form>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '8px',
              marginLeft: '24px',
              marginRight: '24px',
              width: 'full'
            }}
          >
            <div>
              <TextField
                id="search-field"
                size="small"
                variant="outlined"
                value={searchTerm}
                placeholder="Search..."
                onChange={handleSearchChange}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginLeft: '8px' }}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '50px',
                    borderColor: 'GrayText',
                    width: '300px'
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex' }}>
              {/* {_.includes([1], formik.values.productName?.id) && (
                <IconButton
                  color="primary"
                  style={{
                    backgroundColor: '#F3F7FF',
                    border: '1px dashed',
                    borderColor: 'primary.main',
                    borderRadius: '5px',
                    fontSize: 'small',
                    fontWeight: '600',
                    marginRight: '24px'
                  }}
                  size="small"
                  onClick={handleUploadDataClick}
                >
                  <FileUploadIcon />
                  <Typography
                    variant="body2"
                    ml={1}
                    color={colorPrimaryBlue}
                    fontWeight={600}
                    fontSize={'small'}
                  >
                    UPLOAD LICHFL DATA
                  </Typography>
                </IconButton>
              )} */}
              {leadData?.leadList?.length > 0 && (
                <DownloadButton
                  menu={
                    formik.values?.productName?.id === 1
                      ? 'HL_Lead'
                      : formik.values?.productName?.id === 2
                        ? 'LI_Lead'
                        : formik.values?.productName?.id === 4
                          ? 'FD_Lead'
                          : formik.values?.productName?.id === 3
                            ? 'GI_Lead'
                            : formik.values?.productName?.id === 7
                              ? 'NPS_Lead'
                              : formik.values?.productName?.id === 5
                                ? 'MF_Lead'
                                : formik.values?.productName?.id === 6
                                  ? 'IDBI_Lead'
                                  : ''
                  }
                  searchData={searchTerm ? searchTerm : ''}
                  empId={
                    selectedEmployees.length > 0
                      ? selectedEmployees
                          .map(employee => employee.empId)
                          .join(',')
                      : authData?.empId
                  }
                  product={formik.values?.productName?.id}
                  businessType={
                    formik.values.businessType !== null
                      ? formik.values.businessType?.id
                      : BUSINESS_TYPES[0]?.id
                  }
                />
              )}
            </div>
          </div>
          <CustomDataTable
            key={formik.values.productName?.id}
            columns={
              _.includes([1], formik.values.productName?.id)
                ? columns
                : _.includes([2], formik.values?.productName?.id)
                  ? LIColumns
                  : _.includes([3], formik.values.productName?.id)
                    ? GIColumns
                    : _.includes([4], formik.values.productName?.id)
                      ? FDColumns
                      : _.includes([7], formik.values.productName?.id)
                        ? NPSColumns
                        : _.includes([5], formik.values.productName?.id)
                          ? MFColumns
                          : _.includes([6], formik.values.productName?.id)
                            ? IDBIColumns
                            : columns
            }
            data={leadData?.leadList?.length > 0 ? leadData?.leadList : []}
            onPageChange={val => {
              setInitialValues({ ...initialValues, page: val });
            }}
            onRowsPerPageChange={val => {
              setInitialValues({ ...initialValues, limit: val, page: 0 });
            }}
            defaultPage={initialValues.page || 0}
            defaultRowsPerPage={initialValues.limit || 0}
            totalCount={leadData?.totalCount || 0}
            isShowPagination={true}
          />
        </Box>
      </div>

      {uploadLeadDataModalOpen && (
        <ModalDialog
          isShowTitle={true}
          titleName="Upload LICHFL Data"
          fullwidth
        >
          <form autoComplete="off" onSubmit={handleUpload}>
            <div>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '12px',
                  marginBottom: '20px'
                }}
                container
                spacing={3}
              >
                <Grid item lg={12}>
                  <Autocomplete
                    options={productNames}
                    getOptionLabel={option => option.name || ''}
                    value={formik.values.productName}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'productName',
                        newValue ? newValue : null
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Product Name *"
                        placeholder="Enter Product Name"
                        size="small"
                        fullWidth
                        error={
                          formik.touched.productName &&
                          Boolean(formik.errors.productName)
                        }
                        helperText={
                          formik.touched.productName &&
                          formik.errors.productName
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                sx={{ fontWeight: 'bold', marginBottom: '20px' }}
              >
                Download format{' '}
                <a
                  href="#"
                  onClick={handleDownloadFormatClick}
                  style={{ color: '#335DAD', textDecoration: 'none' }}
                >
                  here
                </a>
              </Typography>
              {/* Render the uploaded file if present */}
              {uploadedFile && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '8px',
                    width: 'full',
                    backgroundColor: '#F9F9F9'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '8px'
                    }}
                  >
                    <AttachmentRounded />
                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                      {uploadedFile.name}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      onClick={removeFile}
                      style={{ marginLeft: '8px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              )}

              {/* Drag and Drop Container, shown only if no file is uploaded */}
              {!uploadedFile && (
                <div
                  {...getRootProps()}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px dashed #335DAD',
                    padding: '12px',
                    cursor: 'pointer',
                    backgroundColor: '#F3F7FF',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={ICON_UPLOAD}
                    style={{
                      height: '24px',
                      width: '32px'
                      // marginBottom: '16px'
                    }}
                  ></img>
                  <input {...getInputProps()} />
                  <p>Drag and drop or click to select file</p>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginTop: '20px'
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setUploadLeadDataModalOpen(null);
                    setUploadedFile(null);
                  }}
                >
                  CANCEL
                </Button>
                <LoadingButton variant="contained" type="submit">
                  UPLOAD
                </LoadingButton>
              </div>
            </div>
          </form>
        </ModalDialog>
      )}

      {!isUploadLeadDataLoading && isUploadLeadDataSuccess && (
        <SnackBarBox
          type="success"
          message={handleError(uploadLeadDataStatus)}
        />
      )}

      {!isUploadLeadDataLoading && isUploadLeadDataFailure && (
        <SnackBarBox
          type="error"
          message={handleError(uploadLeadDataErrorContainer?.Message)}
        />
      )}
    </>
  );
}

export default CWALead;
