import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import UploadPhotoComponent from '../components/UploadPhotoComponent';
import { useFormik } from 'formik';
import { createTitle, handleError, isArrayNotEmpty } from '../utils/utils';
import { setActiveMenu } from '../redux/reducers/menuSlice';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import BasicDetails from '../forms/CWAConnectorComponents/BasicDetails';
import ResidentDetails from '../forms/CWAConnectorComponents/ResidentDetails';
import BusinessDetails from '../forms/CWAConnectorComponents/BusinessDetails';
import BankDetails from '../forms/CWAConnectorComponents/BankDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addUpdateCwaConnector,
  getAllConstitutionTypes,
  getAllProfiles,
  getCWAConnectorDetails,
  getEmployeeMapping,
  revertCwaConnectorPageData
} from '../redux/reducers/connectorApprovalSlice';
import { LoadingButton } from '@mui/lab';
import AssociationDetails from '../forms/CWAConnectorComponents/AssociationDetails';
import CWAQuestionsForm from '../forms/CWAConnectorComponents/CWAQuestionsForm';
import References from '../forms/CWAConnectorComponents/References';
import AutherizedSignatory from '../forms/CWAConnectorComponents/AutherizedSignatory';
import SingleDocUpload from '../forms/CWAConnectorComponents/SingleDocUpload';
import SnackBarBox from '../components/SnackBarBox';
import dayjs from 'dayjs';
import ResponseModal from '../components/ResponseModal';

function CWAConnectorEdit() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    cwaConnectorDetails,
    allConstitutionTypes,
    allProfiles,
    isAddUpdateCwaConnectorLoading,
    isAddUpdateCwaConnectorSuccess,
    isAddUpdateCwaConnectorFailed,
    addUpdateCwaConnectorErrorContainer,
    isCWAConnectorDetailsLoading,
    employeeMappingList
  } = useSelector(state => state.connector);
  const connectorId = location.state?.connectorId || '';
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [constitutionType, setConstitutionType] = useState(null);
  useEffect(() => {
    document.title = createTitle(
      `${
        location.pathname === '/cwaf/connector-onboarding/edit' ? 'Edit' : 'Add'
      } Connector`
    );
    dispatch(setActiveMenu('Connector Onboarding'));
    dispatch(getAllConstitutionTypes());
    dispatch(getAllProfiles());
    dispatch(getEmployeeMapping());
    if (location.pathname === '/cwaf/connector-onboarding/edit') {
      setIsFirstTime(false);
    }
  }, []);

  useEffect(() => {
    if (isAddUpdateCwaConnectorSuccess) {
      if (!isAddUpdateCwaConnectorLoading && isAddUpdateCwaConnectorSuccess) {
        setIsSuccessModalOpen(true);
      }
    }
  }, [isAddUpdateCwaConnectorSuccess]);

  useEffect(() => {
    if (location?.state?.pageType === 'edit') {
      dispatch(getCWAConnectorDetails({ connectorId }));
    }
    return () => dispatch(revertCwaConnectorPageData());
  }, [connectorId]);

  useEffect(() => {
    if (location?.state?.connectorId) {
      formik.setValues({
        ...formik.values,
        // Basic details
        connectorPhoto: cwaConnectorDetails?.contactDetails?.photo || '',
        connectorId: cwaConnectorDetails?.connectorId || '',
        name: cwaConnectorDetails?.name || '',
        constitutionType: cwaConnectorDetails?.constitution?.id || null,
        profile: cwaConnectorDetails?.profile?.id || null,
        dob: cwaConnectorDetails?.contactDetails?.dateOfBirth || '',
        dateOfRegistration:
          cwaConnectorDetails?.contactDetails?.dateOfRegistration || '',
        dateOfIncorporation:
          cwaConnectorDetails?.contactDetails?.dateOfIncorporation || '',
        otherConstitutionType: cwaConnectorDetails?.otherConstitution || '',
        otherProfile: cwaConnectorDetails?.otherProfile || '',
        connectorType:
          cwaConnectorDetails?.connectorType == 'S'
            ? {
                id: 'S',
                name: 'Special'
              }
            : cwaConnectorDetails?.connectorType == 'C'
              ? {
                  id: 'C',
                  name: 'Common'
                }
              : null,
        // Residence address
        residentAddress:
          cwaConnectorDetails?.contactDetails?.residentialAddress || '',
        emailId: cwaConnectorDetails?.contactDetails?.email || '',
        mobileNo: cwaConnectorDetails?.mobile || '',
        phoneNo: cwaConnectorDetails?.contactDetails?.telephone || '',
        // Business Details
        bizAddress: cwaConnectorDetails?.bbDetails?.businessAddress || '',
        bizEmailId: cwaConnectorDetails?.bbDetails?.businessEmail || '',
        bizMobileNumber: cwaConnectorDetails?.bbDetails?.businessMobile || '',
        bizSinceYears:
          cwaConnectorDetails?.bbDetails?.noOfYearsInBusiness || '',
        bizPlace: cwaConnectorDetails?.bbDetails?.businessPlace || '',
        panNumber: cwaConnectorDetails?.panNumber || '',
        gstNumber: cwaConnectorDetails?.bbDetails?.gstNumber || '',
        firmRegisteredMSME: cwaConnectorDetails?.isRegisteredMsme
          ? 'Yes'
          : 'No',
        panUpload: cwaConnectorDetails?.connectorDocumentsMap?.pancard || null,
        msmeUpload:
          cwaConnectorDetails?.connectorDocumentsMap?.msmeCertificate || null,
        bankName: cwaConnectorDetails?.bbDetails?.bankName || '',
        branch: cwaConnectorDetails?.bbDetails?.bankBranch || '',
        bankAddress: cwaConnectorDetails?.bbDetails?.bankBranchAddress || '',
        accountNumber: cwaConnectorDetails?.bbDetails?.accountNumber || '',
        accountType: cwaConnectorDetails?.bbDetails?.bankAccountType || '',
        ifscCode: cwaConnectorDetails?.bbDetails?.ifscCode || '',
        micrCode: cwaConnectorDetails?.bbDetails?.micrCode || '',
        // Association details
        associationDetails: cwaConnectorDetails?.associationDetails || [],
        // References
        connectorReferences: cwaConnectorDetails?.connectorReferences || [],
        // Autherized signatory
        connectorSignatories: cwaConnectorDetails?.connectorSignatories || [],
        // Nominee details
        nameofNominee: cwaConnectorDetails?.bbDetails?.nomineeName || '',
        relationWithNominee:
          cwaConnectorDetails?.bbDetails?.nomineeRelation || '',
        // Employee mapping

        employeeMapping: cwaConnectorDetails?.employeeId?.id
          ? {
              id: cwaConnectorDetails?.employeeId?.id || '',
              name: cwaConnectorDetails?.employeeId?.name || ''
            }
          : null,
        // questionForm
        isTimeTaken: cwaConnectorDetails?.bbDetails?.codeOrAgency || false,
        organizationDetails: !cwaConnectorDetails?.bbDetails?.codeOrAgency
          ? 'Dummy Value'
          : cwaConnectorDetails?.bbDetails?.nameOfOrganization || '',
        timePeriod: !cwaConnectorDetails?.bbDetails?.codeOrAgency
          ? 'Dummy Value'
          : cwaConnectorDetails?.bbDetails?.timePeriod || '',
        isRelationship:
          cwaConnectorDetails?.contactDetails?.relativeWorkingInLicHflFsl ||
          false,
        nameOfPersonInRelation: !cwaConnectorDetails?.contactDetails
          ?.relativeWorkingInLicHflFsl
          ? 'Dummy Value'
          : cwaConnectorDetails?.contactDetails?.nameOfPerson || '',
        designationOfPersonInRelation: !cwaConnectorDetails?.contactDetails
          ?.relativeWorkingInLicHflFsl
          ? 'Dummy Value'
          : cwaConnectorDetails?.contactDetails?.designation || '',
        officeOfPersonInRelation: !cwaConnectorDetails?.contactDetails
          ?.relativeWorkingInLicHflFsl
          ? 'Dummy Value'
          : cwaConnectorDetails?.contactDetails?.relativeOffice || '',
        isSubmitted: cwaConnectorDetails?.isFileSubmitted || false,
        submittedName: !cwaConnectorDetails?.isFileSubmitted
          ? 'Dummy Value'
          : cwaConnectorDetails?.submittedFileName || '',
        submittedAmount: !cwaConnectorDetails?.isFileSubmitted
          ? '100'
          : cwaConnectorDetails?.appliedHlAmount || '',

        // Doc
        docUpload: cwaConnectorDetails?.connectorDocumentsMap?.document || null
      });
    }
  }, [cwaConnectorDetails]);

  const validationSchema = Yup.object().shape({
    // Basic Details
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Name must contain only alphabets')
      .required('Name is required'),
    constitutionType: Yup.string().required('Constitution Type is required'),
    profile: Yup.string().required('Profile is required'),
    otherConstitutionType: Yup.string().required(
      'Other Constitution Type is required'
    ),
    otherProfile: Yup.string().required('Other Profile is required'),
    dob: Yup.string().required('Date of Birth is required'),
    dateOfRegistration: Yup.string().required(
      'Date of Registration is required'
    ),
    dateOfIncorporation: Yup.string().required(
      'Date of Incorporation is required'
    ),
    connectorType: Yup.object({
      id: Yup.string().required('Connector type is required')
    }).required('Connector type is required'),
    // Residence address
    residentAddress: Yup.string().required('Residence address is required'),
    emailId: Yup.string().email('Invalid email').required('Email is required'),
    phoneNo: Yup.string()
      .matches(/^\d{10}$/, 'Invalid Phone Number')
      .required('Phone number is required'),
    mobileNo: Yup.string()
      .matches(/^[6789]\d{9}$/, 'Invalid Mobile Number')
      .required('Mobile number is required'),
    // Business details
    bizAddress: Yup.string().required('Business address is required'),
    bizEmailId: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    bizMobileNumber: Yup.string()
      .matches(/^[6789]\d{9}$/, 'Invalid Mobile Number')
      .required('Mobile number is required'),
    bizSinceYears: Yup.string().required('Business Since(years) is required'),
    bizPlace: Yup.string().required('Business Place is required'),
    // panNumber: Yup.string()
    //   .required('Pan Number is required')
    //   .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number format'),
    // gstNumber: Yup.string().required('GST Number is required'),
    panNumber: Yup.string()
      .test(
        'is-valid-panNumber',
        'Invalid PAN Number format',
        function (value) {
          const regex =
            constitutionType === 1
              ? /^[A-Z]{3}P[A-Z][0-9]{4}[A-Z]$/
              : constitutionType === 2
                ? /^[A-Z]{3}F[A-Z][0-9]{4}[A-Z]$/
                : constitutionType === 3
                  ? /^[A-Z]{3}F[A-Z][0-9]{4}[A-Z]$/
                  : constitutionType === 4
                    ? /^[A-Z]{3}C[A-Z][0-9]{4}[A-Z]$/
                    : constitutionType === 5
                      ? /^[A-Z]{3}C[A-Z][0-9]{4}[A-Z]$/
                      : /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
          return regex.test(value);
        }
      )
      .required('Name is required'),

    firmRegisteredMSME: Yup.string().required('This field is required'),

    // Bank details
    bankName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Bank name must contain only alphabets')
      .required('Bank Name is required'),
    branch: Yup.string().required('Branch is required'),
    bankAddress: Yup.string().required('Bank address is required'),
    accountNumber: Yup.string()
      .matches(/^\d{9,18}$/, 'Invalid account number')
      .required('Account Number is required'),
    accountType: Yup.string().required('Account Type is required'),
    ifscCode: Yup.string()
      .matches(
        /^[A-Z]{4}0[A-Z0-9]{6}$/,
        'Invalid IFSC code. Example: ABCD0123456'
      )
      .required('IFSC code is required'),
    micrCode: Yup.string()
      .matches(/^\d{9}$/, 'Invalid MICR code. Example: 123456789')
      .required('MICR code is required'),

    // Autherized signatory

    // Nominee details
    nameofNominee: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Nominee name must contain only alphabets')
      .required('Name of Nominee is required'),
    relationWithNominee: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Relation must contain only alphabets')
      .required('Relation with Nominee is required'),

    // Employee mapping
    employeeMapping: Yup.object({
      id: Yup.string().required('Employee mapping is required')
    }).required('Employee mapping is required'),

    // questionForm
    organizationDetails: Yup.string().required(
      'Organization Details is required'
    ),

    timePeriod: Yup.string().required('Time Period is required'),

    nameOfPersonInRelation: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Name must contain only alphabets')
      .required('Name is required'),
    designationOfPersonInRelation: Yup.string().required(
      'RelationShip Name is required'
    ),
    officeOfPersonInRelation: Yup.string().required('Office is required'),

    submittedName: Yup.string().required('Name is required'),
    submittedAmount: Yup.string()
      .matches(/^[0-9,]+$/, 'Amount must contain only numbers')
      .required('Amount is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      //Basic Details
      connectorPhoto: '',
      connectorPhotoFile: null,
      connectorId: '',
      name: '',
      constitutionType: null,
      otherConstitutionType: '',
      profile: null,
      otherProfile: '',
      dateOfRegistration: '',
      dateOfIncorporation: '',
      dob: '',
      connectorType: null,

      // Residence address
      residentAddress: '',
      phoneNo: '',
      mobileNo: '',
      emailId: '',

      // Business Details
      bizAddress: '',
      bizMobileNumber: '',
      bizEmailId: '',
      bizSinceYears: '',
      bizPlace: '',
      panNumber: '',
      gstNumber: '',
      firmRegisteredMSME: 'No',
      panUpload: null,
      msmeUpload: null,
      panUploadFile: null,
      msmeUploadFile: null,

      // Bank Details
      bankName: '',
      branch: '',
      bankAddress: '',
      accountNumber: '',
      accountType: '',
      ifscCode: '',
      micrCode: '',

      // Association details
      associationDetails: [],

      //Connector References
      connectorReferences: [],

      // Autherized signatory
      connectorSignatories: [],

      // Nominee Details
      nameofNominee: '',
      relationWithNominee: '',

      // Employee Mapping
      employeeMapping: null,

      // questionForm
      isTimeTaken: true,
      organizationDetails: '',
      timePeriod: '',
      isRelationship: true,
      nameOfPersonInRelation: '',
      designationOfPersonInRelation: '',
      officeOfPersonInRelation: '',
      isSubmitted: true,
      submittedName: '',
      submittedAmount: '',

      // Document

      docUpload: '',
      docUploadFile: null,

      // for edit API add param
      isPhotoChange: false,
      isPanChange: false,
      isMsmeChange: false,
      isDocChange: false
    },
    validationSchema: validationSchema,
    onSubmit: values => {}
  });

  useEffect(() => {
    setConstitutionType(formik.values?.constitutionType);
    const isMandatory =
      formik?.values?.constitutionType === 2 ||
      formik?.values?.constitutionType === 3 ||
      formik?.values?.constitutionType === 4;

    const isProfileMandatory =
      formik.values.profile === 1 ||
      formik.values.profile === 2 ||
      formik.values.profile === 3 ||
      formik.values.profile === 4;

    if (formik?.values?.constitutionType === 1) {
      const updatedErrors = { ...formik.errors };
      delete updatedErrors.bizAddress;
      delete updatedErrors.bizEmailId;
      delete updatedErrors.bizMobileNumber;
      delete updatedErrors.dateOfRegistration;
      delete updatedErrors.dateOfIncorporation;
      delete updatedErrors.otherConstitutionType;
      delete updatedErrors.phoneNo;
      if (isProfileMandatory) {
        delete updatedErrors.otherProfile;
      }
      formik.setErrors(updatedErrors);
    } else if (formik?.values?.constitutionType === 5) {
      const updatedErrors = { ...formik.errors };
      delete updatedErrors.bizAddress;
      delete updatedErrors.bizEmailId;
      delete updatedErrors.bizMobileNumber;
      delete updatedErrors.dob;
      delete updatedErrors.otherConstitutionType;
      delete updatedErrors.phoneNo;
      if (isProfileMandatory) {
        delete updatedErrors.otherProfile;
      }
      formik.setErrors(updatedErrors);
    } else if (isMandatory) {
      const updatedErrors = { ...formik.errors };
      delete updatedErrors.dob;
      delete updatedErrors.otherConstitutionType;
      if (isProfileMandatory) {
        delete updatedErrors.otherProfile;
      }
      formik.setErrors(updatedErrors);
    } else if (formik?.values?.constitutionType === 6) {
      const updatedErrors = { ...formik.errors };
      delete updatedErrors.bizAddress;
      delete updatedErrors.bizEmailId;
      delete updatedErrors.bizMobileNumber;
      delete updatedErrors.dob;
      if (isProfileMandatory) {
        delete updatedErrors.otherProfile;
      }
      formik.setErrors(updatedErrors);
    }
  }, [formik]);

  const handleSaveDraftConnector = () => {
    const isPartnershipOrPrivateLtd =
      formik?.values?.constitutionType === 3 ||
      formik?.values?.constitutionType === 4;
    const nameRegex = /^[A-Za-z\s]+$/;
    const mobileRegex = /^\d{10}$/;
    const emailRegex = /\S+@\S+\.\S+/;
    if (!formik?.values?.name) {
      alert('Please enter your name');
      return false;
    } else if (!nameRegex.test(formik?.values?.name)) {
      alert('Name contain only alphabets');
      return false;
    } else if (!formik?.values?.mobileNo) {
      alert('Please enter mobile number');
      return false;
    } else if (!mobileRegex.test(formik?.values?.mobileNo)) {
      alert('Invalid mobile number');
      return false;
    } else if (!formik?.values?.emailId) {
      alert('Please enter email Id');
      return false;
    } else if (!emailRegex.test(formik?.values?.emailId)) {
      alert('Invalid email address');
      return false;
    } else if (!formik?.values?.connectorType) {
      alert('Please select Connector type');
      return false;
    } else if (formik?.values?.connectorReferences?.length < 2) {
      alert('2 References are mandatory');
      return false;
    } else if (
      isPartnershipOrPrivateLtd &&
      formik?.values?.connectorSignatories?.length < 2
    ) {
      alert("2 Authorized Signatory's are mandatory");
      return false;
    } else {
      const updatedAssociationDetails = formik?.values?.associationDetails?.map(
        ({ id, ...rest }) => rest
      );
      const updatedConnectorSignatories =
        formik?.values?.connectorSignatories?.map(({ id, ...rest }) => rest);
      const updatedConnectorReferences =
        formik?.values?.connectorReferences?.map(({ id, ...rest }) => rest);

      let submitPayload = {
        isSentForApproval: false,
        //Basic Details
        connectorId: formik?.values?.connectorId || '',
        name: formik?.values?.name || '',
        constitution: formik?.values?.constitutionType || '',
        profile: formik?.values?.profile || '',
        dateOfBirth: formik?.values?.dob
          ? dayjs(formik?.values?.dob).format('YYYY-MM-DD')
          : '',
        dateOfIncorporation: formik?.values?.dateOfIncorporation
          ? dayjs(formik?.values?.dateOfIncorporation).format('YYYY-MM-DD')
          : '',
        dateOfRegistration: formik?.values?.dateOfRegistration
          ? dayjs(formik?.values?.dateOfRegistration).format('YYYY-MM-DD')
          : '',
        otherConstitution: formik?.values?.otherConstitutionType || '',
        otherProfile: formik?.values?.otherProfile || '',
        connectorType: formik?.values?.connectorType?.id || '',
        // Residence Address
        residentialAddress: formik?.values?.residentAddress || '',
        mobile: formik?.values?.mobileNo || '',
        email: formik?.values?.emailId || '',
        telephone: formik?.values?.phoneNo || '',

        // Business Details
        businessAddress: formik?.values?.bizAddress || '',
        businessEmail: formik?.values?.bizEmailId || '',
        businessMobile: formik?.values?.bizMobileNumber || '',
        noOfYearsInBusiness: formik?.values?.bizSinceYears || '',
        noOfYearsInProfession: formik?.values?.bizSinceYears || '',
        businessPlace: formik?.values?.bizPlace || '',
        gstNumber: formik?.values?.gstNumber || '',
        isRegisteredAsMSME:
          formik?.values?.firmRegisteredMSME === 'Yes' ? true : false,
        panNumber: formik?.values?.panNumber || '',

        // Bank Details

        bankName: formik?.values?.bankName || '',
        bankBranch: formik?.values?.branch || '',
        bankBranchAddress: formik?.values?.bankAddress || '',
        accountNumber: formik?.values?.accountNumber || '',
        bankAccountType: formik?.values?.accountType || '',
        ifscCode: formik?.values?.ifscCode || '',
        micrCode: formik?.values?.micrCode || '',

        // Nominee Details
        nomineeName: formik?.values?.nameofNominee || '',
        nomineeRelation: formik?.values?.relationWithNominee || '',

        // Employee Mapping
        employeeId: formik?.values?.employeeMapping?.id || 0,

        // Tables
        associationDetails: updatedAssociationDetails || [],
        connectorSignatories: updatedConnectorSignatories || [],
        connectorReferences: updatedConnectorReferences || [],

        // Code/Agency
        connectorCodeOrAgencyFromAnyOrg: formik?.values?.isTimeTaken || false,
        nameOfOrganization: formik?.values?.isTimeTaken
          ? formik?.values?.organizationDetails
          : '',
        timePeriod: formik?.values?.isTimeTaken
          ? formik?.values?.timePeriod
          : '',

        // RelationShip LICHFL/LICHFL
        relativeWorkingInLicHflFsl: formik?.values?.isRelationship || false,
        nameOfPerson: formik?.values?.isRelationship
          ? formik?.values?.nameOfPersonInRelation
          : '',
        designation: formik?.values?.isRelationship
          ? formik?.values?.designationOfPersonInRelation
          : '',
        relativeOffice: formik?.values?.isRelationship
          ? formik?.values?.officeOfPersonInRelation
          : '',

        // Files Currently Submitted
        isFileSubmitted: formik?.values?.isSubmitted || false,
        submittedFileName: formik?.values?.isSubmitted
          ? formik?.values?.submittedName
          : '',
        appliedHlAmount: formik?.values?.isSubmitted
          ? formik?.values?.submittedAmount
          : ''
      };

      const formdata = new FormData();
      formdata.append('connectorRequest', JSON.stringify(submitPayload));
      formdata.append('photo', formik?.values?.connectorPhotoFile || null);
      formdata.append('panCard', formik?.values?.panUploadFile || null);
      formdata.append('document', formik?.values?.docUploadFile || null);
      formdata.append(
        'msmeCertificate',
        formik?.values?.msmeUploadFile || null
      );

      dispatch(addUpdateCwaConnector({ payload: formdata, isUpdate: false }));
    }
  };

  const handleSetDetails = (value, index, file) => {
    formik.setValues({
      ...formik.values,
      connectorPhoto: value,
      connectorPhotoFile: file,
      isPhotoChange: true
    });
  };

  const handleUpdateTablesData = (name, data) => {
    if (name === 'AutherizedSignatory') {
      formik.setFieldValue('connectorSignatories', data);
    }
    if (name === 'AssociationDetails') {
      formik.setFieldValue('associationDetails', data);
    }
    if (name === 'Reference') {
      formik.setFieldValue('connectorReferences', data);
    }
  };
  const handleSetDoc = (data, isChange) => {
    if (isChange) {
      formik.setFieldValue('isDocChange', true);
      formik.setFieldValue('docUploadFile', null);
      formik.setFieldValue('docUpload', null);
    }
    if (data.length > 0) {
      formik.setFieldValue('isDocChange', true);
      formik.setFieldValue('docUploadFile', data[0]);
      formik.setFieldValue('docUpload', data[0]);
    }
  };

  function findUnexpectedKeys(obj, predefinedKeysSet) {
    return Object.keys(obj).filter(key => !predefinedKeysSet.has(key));
  }
  const handlePayloadAndCallAPI = () => {
    const updatedAssociationDetails = formik?.values?.associationDetails?.map(
      ({ id, ...rest }) => rest
    );
    const updatedConnectorSignatories =
      formik?.values?.connectorSignatories?.map(({ id, ...rest }) => rest);
    const updatedConnectorReferences = formik?.values?.connectorReferences?.map(
      ({ id, ...rest }) => rest
    );

    let submitPayload = {
      isSentForApproval: true,
      //Basic Details
      connectorId: formik?.values?.connectorId || '',
      name: formik?.values?.name || '',
      constitution: formik?.values?.constitutionType || '',
      profile: formik?.values?.profile || '',
      dateOfBirth: formik?.values?.dob
        ? dayjs(formik?.values?.dob).format('YYYY-MM-DD')
        : '',
      dateOfIncorporation: formik?.values?.dateOfIncorporation
        ? dayjs(formik?.values?.dateOfIncorporation).format('YYYY-MM-DD')
        : '',
      dateOfRegistration: formik?.values?.dateOfRegistration
        ? dayjs(formik?.values?.dateOfRegistration).format('YYYY-MM-DD')
        : '',
      otherConstitution: formik?.values?.otherConstitutionType || '',
      otherProfile: formik?.values?.otherProfile || '',
      connectorType: formik?.values?.connectorType?.id || '',

      // Residence Address
      residentialAddress: formik?.values?.residentAddress || '',
      mobile: formik?.values?.mobileNo || '',
      email: formik?.values?.emailId || '',
      telephone: formik?.values?.phoneNo || '',
      // Business Details
      businessAddress: formik?.values?.bizAddress || '',
      businessEmail: formik?.values?.bizEmailId || '',
      businessMobile: formik?.values?.bizMobileNumber || '',
      noOfYearsInBusiness: formik?.values?.bizSinceYears || '',
      noOfYearsInProfession: formik?.values?.bizSinceYears || '',
      businessPlace: formik?.values?.bizPlace || '',
      gstNumber: formik?.values?.gstNumber || '',
      isRegisteredAsMSME:
        formik?.values?.firmRegisteredMSME === 'Yes' ? true : false,
      panNumber: formik?.values?.panNumber || '',
      msmeS3ObjectKey:
        formik?.values?.firmRegisteredMSME === 'No'
          ? cwaConnectorDetails?.connectorDocumentsMap?.msmeCertificate
              ?.s3ObjectKey
          : '',

      // Bank Details

      bankName: formik?.values?.bankName || '',
      bankBranch: formik?.values?.branch || '',
      bankBranchAddress: formik?.values?.bankAddress || '',
      accountNumber: formik?.values?.accountNumber || '',
      bankAccountType: formik?.values?.accountType || '',
      ifscCode: formik?.values?.ifscCode || '',
      micrCode: formik?.values?.micrCode || '',

      // Nominee Details
      nomineeName: formik?.values?.nameofNominee || '',
      nomineeRelation: formik?.values?.relationWithNominee || '',

      // Employee Mapping
      employeeId: formik?.values?.employeeMapping?.id || 0,

      // Tables
      associationDetails: updatedAssociationDetails || [],
      connectorSignatories: updatedConnectorSignatories || [],
      connectorReferences: updatedConnectorReferences || [],

      // Code/Agency
      connectorCodeOrAgencyFromAnyOrg: formik?.values?.isTimeTaken || false,
      nameOfOrganization: formik?.values?.isTimeTaken
        ? formik?.values?.organizationDetails
        : '',
      timePeriod: formik?.values?.isTimeTaken ? formik?.values?.timePeriod : '',

      // RelationShip LICHFL/LICHFL
      relativeWorkingInLicHflFsl: formik?.values?.isRelationship || false,
      nameOfPerson: formik?.values?.isRelationship
        ? formik?.values?.nameOfPersonInRelation
        : '',
      designation: formik?.values?.isRelationship
        ? formik?.values?.designationOfPersonInRelation
        : '',
      relativeOffice: formik?.values?.isRelationship
        ? formik?.values?.officeOfPersonInRelation
        : '',

      // Files Currently Submitted
      isFileSubmitted: formik?.values?.isSubmitted || false,
      submittedFileName: formik?.values?.isSubmitted
        ? formik?.values?.submittedName
        : '',
      appliedHlAmount: formik?.values?.isSubmitted
        ? formik?.values?.submittedAmount
        : ''
    };

    // Add paramter only for Edit API as per backend integration
    if (location?.state?.pageType === 'edit') {
      submitPayload.isPhotoChange = formik.values?.isPhotoChange;
      submitPayload.isPanChange = formik.values?.isPanChange;
      submitPayload.isMsmeChange = formik.values?.isMsmeChange;
      submitPayload.isDocChange = formik.values?.isDocChange;
    }
    const formdata = new FormData();
    formdata.append('connectorRequest', JSON.stringify(submitPayload));
    formdata.append('photo', formik?.values?.connectorPhotoFile || null);
    formdata.append('panCard', formik?.values?.panUploadFile || null);
    formdata.append('document', formik?.values?.docUploadFile || null);
    formdata.append('msmeCertificate', formik?.values?.msmeUploadFile || null);

    dispatch(
      addUpdateCwaConnector({
        payload: formdata,
        isUpdate: location?.state?.pageType === 'edit' ? true : false
      })
    );
  };
  const handleSubmit = async () => {
    let validate = await formik.validateForm();
    console.log('Error ', {
      Err: formik.errors,
      Tch: formik.touched,
      validate: await formik.validateForm()
    });
    await formik.handleSubmit();

    if (isFirstTime) {
      const predefinedKeysSet = new Set([
        'otherConstitutionType',
        'otherProfile',
        'dateOfRegistration',
        'dateOfIncorporation',
        'bizAddress',
        'bizEmailId',
        'bizMobileNumber',
        'dob'
      ]);

      const testObject = validate;

      const unexpectedKeys = findUnexpectedKeys(testObject, predefinedKeysSet);

      if (unexpectedKeys.length > 0) {
        console.log('Unexpected keys found:', unexpectedKeys);
      } else {
        handlePayloadAndCallAPI();
      }
    } else {
      const isEmpty = Object.keys(formik.errors).length === 0;

      if (isEmpty && !isFirstTime) {
        const isPartnershipOrPrivateLtd =
          formik?.values?.constitutionType === 3 ||
          formik?.values?.constitutionType === 4;

        if (
          formik?.values?.panUpload == null &&
          formik?.values?.panUploadFile == null
        ) {
          alert('Please Upload Pan Card');
          return false;
        } else if (
          formik?.values?.firmRegisteredMSME == 'Yes' &&
          formik?.values?.msmeUpload == null &&
          formik?.values?.msmeUploadFile == null
        ) {
          alert('Please Upload MSME Card');
          return false;
        } else if (formik?.values?.connectorReferences?.length < 2) {
          alert('2 References are mandatory');
          return false;
        } else if (
          isPartnershipOrPrivateLtd &&
          formik?.values?.connectorSignatories?.length < 2
        ) {
          alert("2 Authorized Signatory's are mandatory");
          return false;
        } else {
          handlePayloadAndCallAPI();
        }
      }
    }

    setIsFirstTime(false);
  };
  return (
    <>
      <div
        style={{
          width: 'full',
          margin: '20px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '8px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Typography
          color="#335DAD"
          fontSize={'20px'}
          fontWeight={700}
          marginBottom={'16px'}
        >
          {location?.state?.pageType === 'edit' ? 'Edit' : 'Add Connector'}
        </Typography>

        {isCWAConnectorDetailsLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '80vh',
              marginTop: '10rem',
              zIndex: 10
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        )}

        {!isCWAConnectorDetailsLoading && (
          <form>
            <UploadPhotoComponent
              base64String={
                location?.state?.pageType === 'edit'
                  ? cwaConnectorDetails?.contactDetails?.photo
                  : null
              }
              handleSetImageData={handleSetDetails}
              Index={'Connector'}
              phtotLimit={'2'}
              contentType={
                location?.state?.pageType === 'edit'
                  ? cwaConnectorDetails?.contactDetails?.contentType
                  : null
              }
            />
            <Divider style={{ marginBottom: '12px' }} />

            <BasicDetails
              formik={formik}
              allConstitutionTypes={allConstitutionTypes}
              allProfiles={allProfiles}
            />
            <Divider style={{ marginBottom: '12px' }} />

            <ResidentDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <BusinessDetails formik={formik} />
            <Divider style={{ marginBottom: '12px' }} />

            <AutherizedSignatory
              formik={formik}
              handleUpdateData={handleUpdateTablesData}
            />
            <Divider style={{ marginBottom: '12px' }} />

            <BankDetails formik={formik} />
            <Divider style={{ marginBottom: '20px' }} />

            <div style={{ marginBottom: '2rem' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Nominee details (In case of Individual)
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <TextField
                    label="Name of Nominee*"
                    name="nameofNominee"
                    placeholder="Enter Name of Nominee"
                    size="small"
                    fullWidth
                    value={formik.values.nameofNominee}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.nameofNominee !== ''
                    }}
                    error={
                      formik.touched.nameofNominee &&
                      Boolean(formik.errors.nameofNominee)
                    }
                    helperText={
                      formik.touched.nameofNominee &&
                      formik.errors.nameofNominee
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Relationship with Nominee*"
                    name="relationWithNominee"
                    placeholder="Enter Relationship with Nominee"
                    size="small"
                    fullWidth
                    value={formik.values.relationWithNominee}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.relationWithNominee !== ''
                    }}
                    error={
                      formik.touched.relationWithNominee &&
                      Boolean(formik.errors.relationWithNominee)
                    }
                    helperText={
                      formik.touched.relationWithNominee &&
                      formik.errors.relationWithNominee
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <div style={{ marginBottom: '2rem' }}>
              <Typography
                color="#335DAD"
                fontSize={'18px'}
                fontWeight={600}
                marginBottom={'16px'}
              >
                Employee Mapping
              </Typography>
              <Grid container spacing={3}>
                <Grid item lg={4}>
                  <Autocomplete
                    options={
                      isArrayNotEmpty(employeeMappingList)
                        ? employeeMappingList?.map(c => {
                            return { name: c.name, id: c.id };
                          })
                        : []
                    }
                    disableClearable
                    getOptionLabel={option => option.name || ''}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === (value ? value.id : null);
                    }}
                    value={formik.values.employeeMapping}
                    size="small"
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        'employeeMapping',
                        newValue ? newValue : null
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select Employee Mapping *"
                        placeholder="Select Employee Mapping "
                        error={
                          formik.touched.employeeMapping &&
                          Boolean(formik.errors.employeeMapping)
                        }
                        helperText={
                          formik.touched.employeeMapping &&
                          formik.errors.employeeMapping
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>

            <AssociationDetails
              formik={formik}
              handleUpdateData={handleUpdateTablesData}
            />

            <CWAQuestionsForm formik={formik} />

            <References
              formik={formik}
              handleUpdateData={handleUpdateTablesData}
            />
            <Divider style={{ marginBottom: '12px' }} />

            <Typography
              color="#335DAD"
              fontSize={'18px'}
              fontWeight={600}
              marginBottom={'16px'}
            >
              Upload Documents
            </Typography>
            <Grid container spacing={3}>
              <Grid item lg={6}>
                <SingleDocUpload
                  documentsArr={
                    formik?.values?.docUploadFile
                      ? formik?.values?.docUploadFile
                      : formik?.values?.docUpload
                        ? [formik?.values?.docUpload]
                        : []
                  }
                  handleSetData={handleSetDoc}
                  title=""
                />
              </Grid>
            </Grid>

            <Divider style={{ marginBottom: '12px' }} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                marginTop: '16px',
                marginBottom: '16px'
              }}
            >
              <Button
                variant="outlined"
                sx={{ marginRight: '16px', fontWeight: '600' }}
                onClick={() => {
                  navigate('/cwaf/connector-onboarding');
                }}
              >
                CANCEL
              </Button>
              {location?.state?.pageType === 'add' && (
                <LoadingButton
                  variant="contained"
                  sx={{ marginRight: '16px', paddingX: '32px' }}
                  onClick={handleSaveDraftConnector}
                  loading={isAddUpdateCwaConnectorLoading}
                >
                  SAVE AS DRAFT
                </LoadingButton>
              )}

              <LoadingButton
                variant="contained"
                sx={{ marginRight: '16px', paddingX: '32px' }}
                onClick={handleSubmit}
                loading={isAddUpdateCwaConnectorLoading}
              >
                SEND FOR APPROVAL
              </LoadingButton>
            </div>
          </form>
        )}
        {isSuccessModalOpen && (
          <ResponseModal
            type="success"
            message={
              location?.state?.pageType === 'add'
                ? 'Connector added successfully !'
                : 'Connector updated successfully !'
            }
            buttonText="OK"
            navigationPath={'/cwaf/connector-onboarding'}
            onClose={() => setIsSuccessModalOpen(false)}
          />
        )}
        {/* {!isAddUpdateCwaConnectorLoading && isAddUpdateCwaConnectorSuccess && (
          <SnackBarBox
            type="success"
            message={
              location?.state?.pageType === 'add'
                ? 'Connector added successfully !'
                : 'Connector updated successfully !'
            }
          />
        )} */}

        {!isAddUpdateCwaConnectorLoading && isAddUpdateCwaConnectorFailed && (
          <SnackBarBox
            type="error"
            message={handleError(addUpdateCwaConnectorErrorContainer?.message)}
          />
        )}
      </div>
    </>
  );
}

export default CWAConnectorEdit;
